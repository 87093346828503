<template>
  <v-container fluid text-xs-center>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="9" class="pa-1 ma-0">
        <v-row>
          <v-col cols="12">
            <v-card class="secondarygrad rounded pa-2 pa-md-4 mx-2">
              <v-file-input
                ref="file"
                v-model="uploadedLogo"
                class="d-none"
                @change="onFileSelect($event)"
              />

              <v-dialog v-model="cropDialog" width="500">
                <v-card style="padding:2%">
                  <v-card-text style="margin:2%">
                    <VueCropper
                      v-show="selectedFile"
                      ref="cropper"
                      :src="selectedFile"
                      :aspect-ratio="1"
                      :zoomable="false"
                      :scalable="false"
                      alt="Source Image"
                    ></VueCropper>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="cropDialog = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="primarygrad white--text rounded"
                      @click="saveImage(), (cropDialog = false)"
                      >Crop</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <div class="d-flex">
                <v-avatar
                  v-if="accountDetails"
                  size="70"
                  color="primarygrad"
                  @click="$refs.file.$refs.input.click()"
                >
                  <v-img v-if="logourl != ''" :src="logourl" />
                  <span v-else class="white--text headline font-weight-medium">
                    {{ accountDetails.name.substring(0, 2).toUpperCase() }}
                  </span>
                </v-avatar>
                <div class="pl-3 secondary--text text--lighten-3">
                  <h2 class="font-weight-medium secondary--text">
                    {{ accountDetails.name }}
                  </h2>
                  <v-list-item-subtitle
                    >{{ accountDetails.role }} |
                    {{ accountDetails.username }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle>{{
                    accountDetails.email
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    accountDetails.phone
                  }}</v-list-item-subtitle>
                </div>
              </div>

              <v-divider class="mx-4 my-5" />

              <v-list class="px-4" color="transparent">
                <h3 class="font-weight-medium ma-2">Permissions</h3>
                <v-list-item>
                  <v-layout row wrap>
                    <v-checkbox
                      v-for="(permission, key) of accountDetails.permissions"
                      :key="key"
                      :label="permissionText(key)"
                      :input-value="permission"
                      color="secondary"
                      class="ma-2"
                      hide-details
                      disabled
                    ></v-checkbox>
                  </v-layout>
                </v-list-item>
              </v-list>

              <v-list class="px-4" color="transparent">
                <h3 class="font-weight-medium ma-2">Teams</h3>
                <v-list-item>
                  <v-layout row wrap>
                    <v-chip
                      v-for="(team, j) in accountDetails.teams"
                      :key="j"
                      class="ma-1"
                      label
                    >
                      {{ team.name }}
                    </v-chip>
                  </v-layout>
                </v-list-item>
              </v-list>

              <v-list class="px-4" color="transparent">
                <h3 class="font-weight-medium ma-2">Lists</h3>
                <v-list-item
                  v-if="accountDetails.lists && accountDetails.lists.length > 0"
                >
                  <v-layout row wrap>
                    <v-chip
                      v-for="(list, k) in accountDetails.lists"
                      :key="k"
                      class="ma-1"
                      label
                    >
                      {{ list.name }}
                    </v-chip>
                  </v-layout>
                </v-list-item>
                <div v-else style="padding: 12px 0">
                  <v-icon color="primarygrad">info_outline</v-icon>
                  <span>This Member has no List assigned</span>
                </div>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="preloader"
          class="pa-0 ma-0"
          wrap
          style="padding: 0px; margin: 10px 0px"
        >
          <v-flex xs12 sm12 md12 style="padding: 1px;">
            <v-row style="margin: 0px">
              <v-card style="width:100%; border-radius: 0px">
                <v-card-text>
                  <v-container>
                    <div style="text-align: center" align="center">
                      <v-row
                        class="fill-height"
                        align-content="center"
                        justify="center"
                      >
                        <v-col class="subtitle-1 text-center" cols="12">
                          Please Wait
                        </v-col>
                        <v-col cols="6">
                          <v-progress-linear
                            color="primary"
                            indeterminate
                            rounded
                            height="6"
                          ></v-progress-linear>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-row>
          </v-flex>
        </v-row>
        <v-row v-else-if="totalCalls > 0 && !preloader" class="pa-0 ma-0">
          <v-col cols="12" md="6">
            <v-card class="secondarygrad rounded fill-height">
              <h3 class="text-center py-3 font-weight-medium">
                Disposition Summary of Calls
              </h3>
              <v-card-text>
                <doughnut-chart
                  class="mx-auto"
                  style="max-width: 300px;"
                  :chart-data="datacollection"
                  :options="{
                    legend: {
                      position: 'bottom',
                      display: labelShown,
                    },
                  }"
                ></doughnut-chart>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="secondarygrad rounded fill-height">
              <v-card-title class="justify-center"
                >Performance Graph</v-card-title
              >
              <v-card-text>
                <bar-chart
                  class="mx-auto"
                  style="max-width: 300px;"
                  :chart-data="barChart"
                  :options="{
                    responsive: true,
                    legend: false,
                    scales: {
                      yAxes: [
                        {
                          gridLines: { display: true },
                          ticks: { beginAtZero: true },
                        },
                      ],
                      xAxes: [
                        {
                          type: 'time',
                          gridLines: { display: false },
                          time: { unit: 'day' },
                        },
                      ],
                    },
                  }"
                >
                </bar-chart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else wrap style="padding: 0px; margin: 10px 0px">
          <v-flex xs12 sm12 md12 style="padding: 1px;">
            <v-row style="margin: 0px">
              <v-card class="secondarygrad rounded mx-2" style="width:100%;">
                <v-card-text>
                  <v-container>
                    <div style="text-align: center" align="center">
                      <div align="center" style="margin-bottom: 10px">
                        <v-img
                          :src="noCall"
                          style="width: 100px; justify-content: center"
                        ></v-img>
                      </div>
                      <br />
                      <h2>No Call Record In Selected Month</h2>
                    </div>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-row>
          </v-flex>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="pa-1 ma-0">
        <v-card flat>
          <v-card class="primarygrad rounded">
            <v-card-title class="white--text"> Select Month </v-card-title>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Month"
                  prepend-inner-icon="mdi-calendar"
                  dense
                  solo
                  readonly
                  hide-details="false"
                  v-bind="attrs"
                  class="pa-6"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                color="primarygrad"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="filter">
                  OK
                </v-btn>
                <!-- @click="$refs.dialog.save(date)" -->
              </v-date-picker>
            </v-dialog>
          </v-card>
          <v-card v-if="totalCalls > 0" flat>
            <v-card class="my-4 pa-4 rounded secondarygrad">
              <p class="secondary--text text--lighten-2 py-2 text-center">
                Monthly Average
              </p>
              <v-card-text class="pa-0 ma-0 text-center">
                <h1 class="secondary--text text--darken-1">
                  {{ averageCall }}
                </h1>
                <h3 class="pt-1">Calls</h3>
              </v-card-text>
            </v-card>
            <v-card class="my-4 pa-4 rounded secondarygrad">
              <p class="secondary--text text--lighten-2 py-2 text-center">
                Average Duration Per Call
              </p>
              <v-card-text class="pa-0 ma-0 text-center">
                <h1 class="secondary--text text--darken-1">
                  {{ averageDuration }}
                </h1>
                <h3 class="pt-1">Seconds</h3>
              </v-card-text>
            </v-card>
            <v-card class="my-4 pa-4 rounded secondarygrad">
              <p class="secondary--text text--lighten-2 py-2 text-center">
                Total Calls
              </p>
              <v-card-text class="pa-0 ma-0 text-center">
                <h1 class="secondary--text text--darken-1">{{ totalCalls }}</h1>
                <h3 class="pt-1">Calls</h3>
              </v-card-text>
            </v-card>
            <v-card class="my-4 pa-4 rounded secondarygrad">
              <p class="secondary--text text--lighten-2 py-2 text-center">
                Last Seen
              </p>
              <v-card-text class="pa-0 ma-0 text-center">
                <h2 class="secondary--text text--darken-1">{{ lastSeen }}</h2>
                <h3 class="pt-1">On Call</h3>
              </v-card-text>
            </v-card>
            <v-card class="my-4 pa-4 rounded secondarygrad">
              <p class="secondary--text text--lighten-2 py-2 text-center">
                Idle Time
              </p>
              <v-card-text class="pa-0 ma-0 text-center">
                <h2 class="secondary--text text--darken-1">
                  {{ officeTime * daysWorked - totalTimeSpent }}
                </h2>
                <h3 class="pt-1">Minutes</h3>
              </v-card-text>
            </v-card>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import BarChart from "../js/bar.js";
import DoughnutChart from "../js/doughnut.js";
const _ = require("lodash");
import moment from "moment-timezone";
import compress from "compress-base64";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    BarChart,
    DoughnutChart,
    VueCropper,
  },
  data() {
    return {
      accountId: "",
      dateRange: "",
      accountDetails: "",
      averageCall: "",
      totalCalls: "",
      averageDuration: 0,
      dispositionSummary: [],
      datacollection: {},
      graphdata: [],
      labelShown: true,
      barChart: {},
      lastSeen: "",
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      startDate: "",
      endDate: "",
      dateSelected: "",
      loader: false,
      preloader: false,
      uploadedLogo: null,
      logourl: "",
      logoId: "",
      cropDialog: false,
      selectedFile: null,
      officeTime: null,
      totalTimeSpent: null,
      daysWorked: null,
    };
  },
  computed: {
    ...mapGetters(["dispoList"]),
    noCall() {
      return require("../assets/nocall.png");
    },
  },
  created() {
    this.onCreate();
  },
  methods: {
    filter() {
      this.$refs.dialog.save(this.date);
      var d = this.date.split("-");
      var startDate = moment([d[0], d[1] - 1]).format("YYYY-MM-DD");
      var endDate = moment(startDate)
        .endOf("month")
        .format("YYYY-MM-DD");

      this.modal = false;
      this.dateRange = [startDate, endDate];
      this.dateSelected = moment(this.date).daysInMonth();

      this.getLogsDetail();
    },
    async onCreate() {
      try {
        this.getAccountId(); // get user id
        this.setDates();
        await this.getAccountDetails(); // get user detail
        this.getLogsDetail();
        this.officeTime = this.$moment(
          this.$store.state.companyDetails.officeEndTime,
          "HH:mm"
        ).diff(
          this.$moment(
            this.$store.state.companyDetails.officeStartTime,
            "HH:mm"
          )
        );
        this.officeTime = this.$moment.duration(this.officeTime).asMinutes();
      } catch (e) {
        console.log(e);
      }
    },
    // Get user id from parmas
    getAccountId() {
      if (this.$router.currentRoute && this.$router.currentRoute.params.id) {
        this.accountId = this.$router.currentRoute.params.id;
      }
    },
    // Date selection
    setDates() {
      var startDate = moment([moment().year(), moment().month()]).format(
        "YYYY-MM-DD"
      );
      var endDate = moment(startDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      this.dateRange = [startDate, endDate];
      this.dateSelected = moment(this.date).daysInMonth();
    },
    permissionText(type) {
      if (type == "crm") {
        return "Access CRM on App";
      } else if (type == "modifyUser") {
        return "Modify Member";
      } else if (type == "canSkipCall") {
        return "Skip Call";
      } else if (type == "canRecord") {
        return "Recording";
      } else if (type == "canAccessList") {
        return "Access List";
      } else if (type == "canAccessCallLog") {
        return "Access Call Logs";
      } else {
        return "Error";
      }
    },
    // Get user details
    async getAccountDetails() {
      if (this.accountId != "") {
        try {
          var filter = {
            include: [
              {
                relation: "lists",
                scope: {
                  fields: ["id", "name"],
                },
              },
              {
                relation: "teams",
                scope: {
                  fields: ["id", "name"],
                },
              },
            ],
          };
          var response = await this.$http.get(
            `${this.$store.state.ENDPOINT}/accounts/${
              this.accountId
            }?filter=${encodeURIComponent(JSON.stringify(filter))}`
          );
          response = response.body;
          this.accountDetails = response;
          this.logoId =
            typeof response.pictureId != "undefined" ? response.pictureId : "";
          this.logourl =
            typeof response.picture != "undefined"
              ? `${this.$store.state.ENDPOINT}/storages/get/${response.picture}?access_token=${this.$store.getters.user.token}`
              : "";
        } catch (err) {
          console.log(err);
          //error handling
        }
      }
    },

    // Get the user call stats and bar chart presentation
    getLogsDetail() {
      this.preloader = true;
      let dates = [
        moment(this.dateRange[0]).format("YYYY-MM-DDT00:00:01"),
        moment(this.dateRange[1]).format("YYYY-MM-DDT00:00:01"),
      ];
      // api call
      this.$http
        .post(
          `${this.$store.state.ENDPOINT}/accounts/${this.accountId}/stats`,
          {
            dates,
          }
        )
        .then((response) => {
          response = response.body;
          // Last Seen
          if (response.length > 0)
            this.lastSeen = moment(response[0].modifiedOn).format("lll");

          var labels,
            data = [];

          // Labels
          labels = _.uniq(
            _.map(response, (r) => {
              return moment(r._id).format("YYYY-MM-DD");
            })
          );

          // Data
          data = _.map(labels, (l) => {
            let i = 0;
            response.forEach((res) => {
              if (moment(res._id).format("YYYY-MM-DD") == l) {
                i = i + res.calls;
              }
            });
            return i;
          });

          this.barChart = {
            datasets: [
              {
                backgroundColor: "#4cd964",
                data: data,
              },
            ],
            labels: labels,
          };
          this.preloader = false;
          let dispos = [];
          response.forEach((res) => {
            for (var y in res.status) {
              let exists = _.find(dispos, (s) => {
                return s._id == res.status[y]["_id"];
              });
              let index = _.indexOf(dispos, exists);
              if (index > -1) {
                dispos[index].count =
                  dispos[index].count + res.status[y]["count"];
                continue;
              }
              dispos.push({
                _id: res.status[y]["_id"],
                count: res.status[y]["count"],
              });
            }
          });

          this.averageDuration = Math.round(
            _.sum(_.map(response, "duration")) / (_.sum(data) * 1000)
          );
          this.averageCall = Math.round(_.sum(data) / this.dateSelected);
          this.totalTimeSpent = Number(
            _.sum(_.map(response, "timeSpentOnCall")) / 1000 / 60
          ).toFixed(1);
          this.daysWorked = response.length;
          this.fillDoughnutData(dispos);
        })
        .catch((err) => {
          console.log(err);
          // error handling
        });
    },

    fillDoughnutData(dispos) {
      const self = this;
      self.datacollection = {};
      self.labelShown = false;
      var colors = [];
      _.each(dispos, (val) => {
        val = _.find(self.dispoList, (d) => {
          return d.title == val._id;
        });
        if (val == undefined) {
          colors.push("#d9d9d9");
        } else {
          colors.push(val.hex);
        }
      });
      this.labelShown = false;
      let data = _.map(dispos, "count");
      this.totalCalls = _.sum(data);

      // Chart
      self.datacollection = {
        datasets: [
          {
            backgroundColor: colors,
            data: data,
            borderWidth: 0,
          },
        ],
        labels: _.map(dispos, "_id"),
      };

      self.labelShown = true;
    },
    onFileSelect(file) {
      this.mime_type = file.type;
      this.fileName = file.name;
      if (typeof FileReader === "function") {
        this.cropDialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    async uploadFile(file) {
      try {
        const self = this;
        // this.loader = true;
        var formData = new FormData();
        formData.append("file", file);
        formData.append("type", "picture");
        formData.append("companyId", self.$store.state.companyDetails.id);

        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: self.$store.state.user.token,
          },
        };

        //delete previous logo
        if (self.accountDetails.picture) {
          await self.$http.delete(
            `${self.$store.state.ENDPOINT}/attachments/${self.accountDetails.pictureId}`
          );
        }

        //store the file to storage
        await self.$http
          .post(
            `${self.$store.state.ENDPOINT}/attachments/upload`,
            formData,
            config
          )
          .then((attachment) => {
            attachment = attachment.body;

            self.$http
              .patch(
                `${self.$store.state.ENDPOINT}/accounts/` +
                  self.accountDetails.id,
                {
                  pictureId: attachment.id,
                  picture: attachment.name,
                }
              )
              .then((res) => {
                try {
                  fb.log("profile_picture_added");
                } catch (e) {
                  console.log("Error in FB log", e);
                }
              })
              .catch((e) => {
                console.log("error", e);
              });

            self.getAccountDetails();
            // self.loader = false;
            self.$swal({
              type: "success",
              text: "Profile Picture Updated",
            });
          })
          .catch((err) => {
            console.log("error", err);
          });

        //   setTimeout(() => {

        // }, 5000);
      } catch (e) {
        console.log(e);
        // self.loader = false;
      }
    },

    saveImage() {
      const self = this;
      const compressor = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            compress(event.target.result, {
              width: 512,
              type: "image/png", // default
              max: 2048, // max size
              min: 2, // min size
              quality: 0.5,
            })
              .then((result) => {
                resolve(result);
              })
              .catch((e) => {
                console.log("err", e);
              });
          };
          reader.onerror = (error) => reject(error);
        });

      function srcToFile(src, fileName, mimeType) {
        return fetch(src)
          .then(function(res) {
            return res.arrayBuffer();
          })
          .then(function(buf) {
            return new File([buf], fileName, { type: mimeType });
          });
      }

      var uncompressed = self.$refs.cropper.getCroppedCanvas().toDataURL();

      srcToFile(uncompressed, self.fileName, "image/png").then(async (file) => {
        var compressed = await compressor(file);
        self.logourl = compressed;
        srcToFile(compressed, self.fileName, "image/png").then(
          async (cFile) => {
            self.uploadFile(cFile);
          }
        );

        // --------- Not Used ------------//
        // var payload = {
        //   data: self.logourl,
        //   name: self.fileName,
        //   accountsId: self.accountId,
        // };
        // if (
        //   this.logoId != null &&
        //   typeof this.logoId != "undefined" &&
        //   this.logoId != ""
        // ) {
        //   payload.id = this.logoId;
        // }
        // self.$http
        //   .put(`${self.$store.state.ENDPOINT}/dbattachments/`, payload)
        //   .then((response) => {
        //     // console.log("response body",response.body)
        //     self.$swal({ type: "success", text: "Successful" });
        //     // self.refresh();
        //   })
        //   .catch((err) => {
        //     self.$swal({
        //       type: "error",
        //       text: "Something went wrong. Try again later",
        //     });
        //   });
      });
    },
  },
};
</script>

<style scope>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}
</style>
